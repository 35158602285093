<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                x-small
                v-on="on"
                class="mr-1"
                color="grey darken-3"
                :to="{
                  name: page_route,
                }"
              >
                <v-icon v-text="'mdi-arrow-left'" />
              </v-btn>
            </template>
            <span v-text="'Atrás'" />
          </v-tooltip>
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip
            bottom
            v-if="
              login.permissions.contacto_mensajes.update &&
              data &&
              data.discharged == null &&
              data.contact_message_type_id == null
            "
          >
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                x-small
                v-on="on"
                class="ml-1"
                color="warning"
                @click.prevent="responseDialog"
              >
                <v-icon> mdi-receipt-send </v-icon>
              </v-btn>
            </template>
            <span v-text="'Responder'" />
          </v-tooltip>
          <v-tooltip
            bottom
            v-if="
              login.permissions.contacto_mensajes.update &&
              data &&
              data.discharged == null &&
              data.contact_message_type_id != null
            "
          >
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                x-small
                v-on="on"
                class="ml-1"
                color="success"
                @click.prevent="dischargedSubmit"
              >
                <v-icon> mdi-receipt-text-check </v-icon>
              </v-btn>
            </template>
            <span v-text="'Terminar'" />
          </v-tooltip>
          <v-tooltip
            v-if="
              login.permissions.contacto_mensajes.update &&
              data &&
              data.discharged == null
            "
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                x-small
                v-on="on"
                class="ml-1"
                color="info"
                :to="{ name: `${page_route}.update`, params: { id: id } }"
              >
                <v-icon> mdi-pencil </v-icon>
              </v-btn>
            </template>
            <span v-text="'Editar'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="data">
      <v-row>
        <v-col cols="12" v-if="data.discharged">
          <v-alert type="success" dense>
            <div class="text-center">
              Registro terminado el {{ data.discharged }}
            </div>
          </v-alert>
        </v-col>
        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="`FOLIO | ${data.folio}`" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="Nombre" :value="data.name" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="A. paterno" :value="data.first_surname" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="A. materno" :value="data.second_surname" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="ID SM" :value="data.enrollment" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="C. electrónico" :value="data.email" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="Fecha" :value="data.email_date" />
                </v-col>
                <v-col cols="12">
                  <ViewData label="Mensaje" :value="data.message" />
                </v-col>
                <v-col v-if="data.contact_message_type_id != null" cols="12">
                  <v-divider class="pb-1" />
                </v-col>
                <v-col
                  v-if="data.contact_message_type_id != null"
                  cols="12"
                  xs="12"
                  md="4"
                >
                  <ViewData
                    label="Tipo"
                    :value="data.contact_message_type.contact_message_type"
                  />
                </v-col>
                <v-col
                  v-if="data.contact_message_type_id == 2"
                  cols="12"
                  xs="12"
                  md="4"
                >
                  <ViewData
                    label="Subtipo"
                    :value="
                      data.contact_message_subtype.contact_message_subtype
                    "
                  />
                </v-col>
                <v-col
                  v-if="data.contact_message_type_id == 2"
                  cols="12"
                  xs="12"
                  md="4"
                >
                  <ViewData
                    label="Monto"
                    :value="numberFormat(data.amount_typed)"
                  />
                </v-col>
                <v-col v-if="data.contact_message_type_id != null" cols="12">
                  <ViewData label="Respuesta" :value="data.response" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col v-if="data.documents && data.documents.length > 0" cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'DOCUMENTOS'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items />
            </v-toolbar>
            <v-card-text>
              <v-row dense>
                <v-col
                  cols="12"
                  v-for="(document, i) in data.documents"
                  :key="i"
                >
                  <v-row dense v-if="document.active">
                    <v-col cols="12" sm="12" md="8">
                      <span
                        class="text-caption font-weight-bold"
                        v-text="`Documento (${i + 1})`"
                      />
                      <br />
                      <span
                        class="text-description"
                        v-text="document.description"
                      />

                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            small
                            v-on="on"
                            :href="
                              url_documents +
                              '/contact_messages/' +
                              document.url
                            "
                            target="_blank"
                          >
                            <v-icon small>mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span v-text="'Ver'" />
                      </v-tooltip>
                    </v-col>
                    <v-col
                      v-if="data.contact_message_type_id == 2"
                      cols="12"
                      sm="12"
                      md="4"
                    >
                      <ViewData
                        label="Tipo"
                        :value="
                          document.document_type.contact_message_document_type
                        "
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-divider class="pb-1" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'REGISTRO'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Creación"
                    :value="data.created_by.email"
                    :subvalue="data.created_at"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="U. actualización"
                    :value="data.updated_by.email"
                    :subvalue="data.updated_at"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-tooltip v-if="login.permissions.contacto_mensajes.delete" top>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                x-small
                v-on="on"
                class="ml-1"
                :loading="loading"
                color="error"
                @click.prevent="HandleDesactivate"
              >
                <v-icon v-text="'mdi-delete'" />
              </v-btn>
            </template>
            <span v-text="'Desactivar'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog v-model="response_dialog" scrollable persistent max-width="800">
      <v-card
        tile
        :disabled="response_dialog_loading"
        :loading="response_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> RESPUESTA </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="response_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="response_data">
          <v-form v-on:submit.prevent ref="response_form" lazy-validation>
            <v-row>
              <v-col cols="12" />
              <v-col cols="12">
                <v-card tile>
                  <v-toolbar elevation="1" dense>
                    <v-toolbar-title>
                      <span class="text-caption" v-text="'GENERAL'" />
                    </v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items />
                  </v-toolbar>
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" xs="12" md="6">
                        <v-autocomplete
                          label="Tipo*"
                          v-model="response_data.contact_message_type_id"
                          :items="contact_message_types"
                          item-value="id"
                          :item-text="(v) => v.contact_message_type"
                          :loading="contact_message_types_loading"
                          dense
                          :rules="rules.required"
                          @change="
                            response_data.contact_message_subtype_id = null
                          "
                        />
                      </v-col>
                      <v-col
                        v-if="response_data.contact_message_type_id == 2"
                        cols="12"
                        xs="12"
                        md="6"
                      >
                        <v-autocomplete
                          label="Subtipo*"
                          v-model="response_data.contact_message_subtype_id"
                          :items="contact_message_subtypes"
                          item-value="id"
                          :item-text="(v) => v.contact_message_subtype"
                          :loading="contact_message_subtypes_loading"
                          dense
                          :rules="rules.required"
                        />
                      </v-col>
                      <!-- <v-col
                        xs="12"
                        md="6"
                        v-if="response_data.contact_message_type_id == 1"
                      >
                        <v-btn
                          block
                          color="success"
                          dark
                          small
                          @click.prevent="insuredDialog"
                        >
                          BUCAR ASEGURADO
                        </v-btn>
                      </v-col>
                      <v-col
                        xs="12"
                        md="6"
                        v-if="response_data.contact_message_type_id == 2"
                      >
                        <v-btn block color="info" dark small>
                          BUCAR FOLIO
                        </v-btn>
                      </v-col> -->
                      <v-col
                        cols="12"
                        v-if="response_data.contact_message_type_id"
                      >
                        <v-textarea
                          v-model="response_data.response"
                          label="Mensaje"
                          dense
                          rows="1"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                v-if="
                  response_data.contact_message_type_id == 2 &&
                  response_data.contact_message_subtype_id &&
                  response_data.contact_message_documents
                "
              >
                <v-card tile>
                  <v-toolbar elevation="1" dense>
                    <v-toolbar-title>
                      <span class="text-caption" v-text="'DOCUMENTOS'" />
                    </v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items />
                  </v-toolbar>
                  <v-card-text>
                    <v-row dense>
                      <v-col
                        cols="12"
                        v-for="(
                          contact_message_document, i
                        ) in response_data.contact_message_documents"
                        :key="i"
                      >
                        <v-row>
                          <v-col cols="12" sm="12" md="6">
                            <span
                              class="text-caption font-weight-bold"
                              v-text="`Documento (${i + 1})`"
                            />
                            <br />
                            <span
                              class="text-description"
                              v-text="contact_message_document.description"
                            />

                            <v-tooltip right>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  icon
                                  small
                                  v-on="on"
                                  :href="
                                    url_documents +
                                    '/contact_messages/' +
                                    contact_message_document.url
                                  "
                                  target="_blank"
                                >
                                  <v-icon small>mdi-eye</v-icon>
                                </v-btn>
                              </template>
                              <span v-text="'Ver'" />
                            </v-tooltip>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <br />
                            <v-select
                              label="Tipo*"
                              v-model="
                                contact_message_document.document_type_id
                              "
                              :items="contact_message_document_types"
                              item-value="id"
                              :item-text="
                                (v) => v.contact_message_document_type
                              "
                              :loading="contact_message_document_types_loading"
                              dense
                              :rules="rules.required"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          label="Monto*"
                          v-model="response_data.amount_typed"
                          type="number"
                          :rules="rules.required"
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  color="warning"
                  dark
                  @click.prevent="responseSubmit"
                  small
                  :disabled="!response_data.contact_message_type_id"
                >
                  ACEPTAR
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  URL_DOCUMENTS,
  msgConfirm,
  msgAlert,
  rules,
} from "../../control";
import ViewData from "../../components/ViewData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    ViewData,
    FaqDlg,
  },
  data() {
    return {
      page_route: "contacto_mensajes",
      api: "contact/messages",
      id: this.$attrs.id,
      login: this.$store.getters.getLogin,
      rules: rules(),
      loading: false,
      data: null,
      url_documents: URL_DOCUMENTS,
      response_dialog: false,
      response_dialog_loading: false,
      response_data: null,
      contact_message_types: [],
      contact_message_types_loading: true,
      contact_message_subtypes: [],
      contact_message_subtypes_loading: true,
      contact_message_document_types: [],
      contact_message_document_types_loading: true,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getData() {
      this.loading = true;

      Axios.get(
        `${URL_API}/${this.api}/${this.id}`,
        headersToken(this.login.token)
      ).then((response) => {
        this.data = response.data.data;
        this.loading = false;
      });
    },
    HandleDesactivate() {
      this.$swal
        .fire(msgConfirm("Confirma la desactivación del registro?"))
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;

            Axios.delete(
              `${URL_API}/${this.api}/${this.id}`,
              headersToken(this.login.token)
            ).then((response) => {
              this.$swal.fire(
                msgAlert(
                  response.data.success ? "success" : "error",
                  response.data.message
                )
              );

              response.data.success
                ? this.$router.push({ name: this.page_route })
                : console.log(response.data.message);
            });
          }
        });
    },
    responseDialog() {
      this.response_data = {
        id: this.data.id,
        contact_message_type_id: null,
        response: "",
        rs_service_isured_bill_id: null,
        insured_id: null,
        contact_message_subtype_id: null,
        amount_typed: "0",
        contact_message_documents: this.data.documents,
      };
      this.response_dialog_loading = false;
      this.response_dialog = true;
    },
    responseSubmit() {
      if (this.$refs.response_form.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma enviar respuesta?`))
          .then((resp) => {
            if (resp.isConfirmed) {
              this.response_dialog_loading = true;

              Axios.post(
                `${URL_API}/contact/messages/response`,
                this.response_data,
                headersToken(this.login.token)
              ).then((resp) => {
                this.$swal.fire(
                  msgAlert(
                    resp.data.success ? "success" : "error",
                    resp.data.message
                  )
                );

                if (resp.data.success) {
                  this.response_dialog = false;
                  this.getData();
                } else {
                  console.log(resp.data.message);
                }

                this.response_dialog_loading = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    dischargedSubmit() {
      this.$swal
        .fire(msgConfirm(`¿Confirma terminar registro?`))
        .then((resp) => {
          if (resp.isConfirmed) {
            this.loading = true;

            Axios.post(
              `${URL_API}/contact/messages/discharged`,
              { id: this.data.id },
              headersToken(this.login.token)
            ).then((resp) => {
              this.$swal.fire(
                msgAlert(
                  resp.data.success ? "success" : "error",
                  resp.data.message
                )
              );

              if (resp.data.success) {
                this.getData();
              } else {
                console.log(resp.data.message);
              }
            });
          }
        });
    },
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.getData();

    Axios.get(
      `${URL_API}/catalog?name=contact_message_types&no_order=false`,
      headersToken(this.login.token)
    ).then((resp) => {
      this.contact_message_types = resp.data.data;
      this.contact_message_types_loading = false;
    });

    Axios.get(
      `${URL_API}/catalog?name=contact_message_subtypes&no_order=false`,
      headersToken(this.login.token)
    ).then((resp) => {
      this.contact_message_subtypes = resp.data.data;
      this.contact_message_subtypes_loading = false;
    });

    Axios.get(
      `${URL_API}/catalog?name=contact_message_document_types&no_order=false`,
      headersToken(this.login.token)
    ).then((resp) => {
      this.contact_message_document_types = resp.data.data;
      this.contact_message_document_types.push({
        id: null,
        contact_message_document_type: "N/A",
      });
      this.contact_message_document_types_loading = false;
    });
  },
};
</script>